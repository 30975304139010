objectFitImages('.ofi_img');
$("#sp-menu").click(function(){
  if($("#sp-menu, nav, header").hasClass("clicked")){
    $("#sp-menu, nav, header").removeClass("clicked");
  }else{
    $("#sp-menu, nav, header").addClass("clicked");
  }
});
// $(function(){
//   var current_scroll_y;
//   $('#sp-menu').on('click', function (event) {
//     $(this).toggleClass('open');
//     $('nav').toggleClass('open');

//     current_scroll_y = $(window).scrollTop();
//     if ($(this).hasClass('open')) {
//       setTimeout(function () {
//         $('body').css({
//           "position": "fixed",
//           "width": "100%",
//           top: -1 * current_scroll_y
//         });
//       }, 100);
//     } else {
//       var pos = $('body').offset();
//       $('body').attr({ style: "" });
//       $("html, body").prop({
//         scrollTop: -pos.top
//       });
//     }
//   });
// });

$(function(){
  $('.slider1').slick({
      autoplay: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      fade: true,
      asNavFor: '.thumb_list',
      pauseOnHover: false,
      pauseOnFocus: false,
      autoplaySpeed: 5000,
  });
  $('.thumb_list').slick({
      accessibility: true,
      autoplay: true,
      autoplaySpeed: 5000,
      speed: 400,
      arrows: true,
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      asNavFor: '.slider1',
      focusOnSelect: true,
  });
  $('.slider1').on('beforeChange', function(event, slick, currentSlide, nextSlide){
    if(nextSlide == 0){
      $('.slide-item.item01').parents(".slick-slide").css( 'z-index','3' );
      $('.slide-item.item02').parents(".slick-slide").css( 'z-index','2' );
      $('.slide-item.item03').parents(".slick-slide").css( 'z-index','1' );
      $('.slide-item.item04').parents(".slick-slide").css( 'z-index','-1' );
    }else if(nextSlide == 1){
      $('.slide-item.item01').parents(".slick-slide").css( 'z-index','2' );
      $('.slide-item.item02').parents(".slick-slide").css( 'z-index','3' );
      $('.slide-item.item03').parents(".slick-slide").css( 'z-index','1' );
      $('.slide-item.item04').parents(".slick-slide").css( 'z-index','-1' );
    }else if(nextSlide == 2){
      $('.slide-item.item01').parents(".slick-slide").css( 'z-index','1' );
      $('.slide-item.item02').parents(".slick-slide").css( 'z-index','2' );
      $('.slide-item.item03').parents(".slick-slide").css( 'z-index','3' );
      $('.slide-item.item04').parents(".slick-slide").css( 'z-index','-1' );
    }else if(nextSlide == 3){
      $('.slide-item.item01').parents(".slick-slide").css( 'z-index','-1' );
      $('.slide-item.item02').parents(".slick-slide").css( 'z-index','1' );
      $('.slide-item.item03').parents(".slick-slide").css( 'z-index','2' );
      $('.slide-item.item04').parents(".slick-slide").css( 'z-index','3' );
    }
  });
  $('.slider2').slick({
    infinite: true,
    dots: true,
    slidesToShow: 3,
    slidesToScroll: 1,
  });
});
$(function(){
  function sliderSetting(){
    var width = $(window).width();
    if(width <= 767){
      $('.sp_slick').not('.slick-initialized').slick({
          dots: true,
          arrows: true,
          centerMode: true,
          centerPadding: '10%',
      });
    } else {
      $('.sp_slick.slick-initialized').slick('unslick');
    }
  }
  sliderSetting();
  $(window).resize( function() {
      sliderSetting();
  });
});

$(document).ready(function () {
  var bg_size = $(window).width();
  if (bg_size > 1200) {
    $(".page_top .fv .slide-item span.img").css("background-size", bg_size + "px");
    $(".offering_partner .partner_voice .slide-item span.img").css("background-size", "cover");
  }
})
$(window).resize(function () {
  var bg_size = $(window).width();
  if (bg_size > 1200) {
    $(".page_top .fv .slide-item span.img").css("background-size", bg_size + "px");
  }
});

$(function () {
  $('.tabCont').hide();
  $('.tabCont').eq(0).show();
  $('.changeTabBtn').eq(0).addClass('active');
  $('.changeTabBtn').each(function () {
    $(this).on('click', function () {
      var index = $('.changeTabBtn').index(this);
      $('.changeTabBtn').removeClass('active');
      $(this).addClass('active');
      $('.tabCont').hide();
      $('.tabCont').eq(index).show();
    });
  });
});

$(function(){
  $('.bgImgChange').hover(
    function(){
      $('.bgImgChange .item .img').fadeTo('1000', 0);
    },
    function(){
      $('.bgImgChange .item .img').fadeTo('1000', 1);
    }
  );

  $('.bgImgChange .item').hover(
    function(){
      var i = $('.bgImgChange .item').index(this);
      $('.bgImgChange').addClass('is_hover');
      $('.bgImgChange .item').eq(i).addClass('is_hover');
      $('.pop_list li').eq(i).addClass('is_hover');
      $('.pop_list li').eq(i).stop().fadeTo('1000',1);
    },
    function(){
      var i = $('.bgImgChange .item').index(this);
      $('.bgImgChange').removeClass('is_hover');
      $('.bgImgChange .item').eq(i).removeClass('is_hover');
      $('.pop_list li').eq(i).removeClass('is_hover');
      $('.pop_list li').eq(i).stop().fadeTo('1000',0);
    }
  );
});

$(function() {
  $('#change_tel').attr("type", 'tel');
});
